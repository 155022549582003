import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';
import { simplifyFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticOrderOfOperationsB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9];
    const generatedProblem = [];

    let num1 = nums[Math.floor(Math.random()*nums.length)];
    let num2 = nums[Math.floor(Math.random()*nums.length)];
    let num3 = nums[Math.floor(Math.random()*nums.length)];
    let num4 = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(num1 === num2 || num1 === num3 || num2 === num3) {
      num1 = nums[Math.floor(Math.random()*nums.length)];
      num2 = nums[Math.floor(Math.random()*nums.length)];
    }
    
    let t1 = num1 + num2;
    let b1 = num3;
    let result1 = displayFraction(t1,b1);
    let t2 = num1 + num2*num3;
    let b2 = num3;
    let result2 = displayFraction(t2,b2);
    let t3 = num1;
    let b3 = num2 + num3;
    let result3 = displayFraction(t3,b3);
    let t4 = num1 + num2;
    let b4 = num3;
    let result4 = displayFraction(t4,b4);
    let t5 = Math.pow(num1,2);
    let b5 = Math.pow(num3,2);
    let result5 = displayFraction(t5,b5);
    let t6 = Math.pow(num1,2);
    let b6 = Math.pow(num3,2);
    let result6 = displayFraction(t6,b6);
    let t7 = Math.pow(num3,2);
    let b7 = Math.pow(num1,2);
    let result7 = displayFraction(t7,b7);
    let t8 = Math.pow(num1 + num2,2);
    let b8 = Math.pow(num3,2);
    let result8 = displayFraction(t8,b8);
    let t9 = Math.pow(num1 + num2,2);
    let b9 = num3;
    let result9 = displayFraction(t9,b9);
    let t10 = (num1 - num2*num3)*num4;
    let b10 = num3;
    let result10 = displayFraction(t10,b10);
    let t11 = num1 - num2*num3*num4;;
    let b11 = num3;
    let result11 = displayFraction(t11,b11);
    let t12 = num1*num4;
    let b12 = num3 + num2;
    let result12 = displayFraction(t12,b12);
    let t13 = num1;
    let b13 = num3 + num2*num4;
    let result13 = displayFraction(t13,b13);
    let t14 = num1*num4 - num2*num3;
    let b14 = num3*num4;
    let result14 = displayFraction(t14,b14);
    let t15 = num1 - num2;
    let b15 = num3 + num4;
    let result15 = displayFraction(t15,b15);

    generatedProblem.push({
      key: "1-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result1,
      format: <span>{`$ \\dfrac{${num1} + ${num2}}{${num3}} $`}</span>
    });

    generatedProblem.push({
      key: "2-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result2,
      format: <span>{`$ \\dfrac{${num1}}{${num3}} + ${num2} $`}</span>
    });

    generatedProblem.push({
      key: "3-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result3,
      format: <span>{`$ \\dfrac{${num1}}{${num3} + ${num2}} $`}</span>
    });

    generatedProblem.push({
      key: "4-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result4,
      format: <span>{`$ \\dfrac{${num1}}{${num3}} + \\dfrac{${num2}}{${num3}} $`}</span>
    });

    generatedProblem.push({
      key: "5-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result5,
      format: <span>{`$ \\left( \\dfrac{${num1}}{${num3}} \\right)^{2} $`}</span>
    });

    generatedProblem.push({
      key: "6-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result6,
      format: <span>{`$ \\dfrac{${num1}^{2}}{${num3}^{2}} $`}</span>
    });

    generatedProblem.push({
      key: "7-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result7,
      format: <span>{`$ \\left( \\dfrac{${num1}}{${num3}} \\right)^{-2} $`}</span>
    });

    generatedProblem.push({
      key: "8-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result8,
      format: <span>{`$ \\left( \\dfrac{${num1} + ${num2}}{${num3}} \\right)^{2} $`}</span>
    });

    generatedProblem.push({
      key: "9-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result9,
      format: <span>{`$ \\dfrac{(${num1} + ${num2})^{2}}{${num3}} $`}</span>
    });

    generatedProblem.push({
      key: "10-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result10,
      format: <span>{`$ \\left( \\dfrac{${num1}}{${num3}} - ${num2} \\right) \\cdot ${num4} $`}</span>
    });

    generatedProblem.push({
      key: "11-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result11,
      format: <span>{`$ \\dfrac{${num1}}{${num3}} - ${num2} \\cdot ${num4} $`}</span>
    });

    generatedProblem.push({
        key: "12-an",
        num1: num1,
        num2: num2,
        num3: num3,
        num4: num4,
        result: result12,
        format: <span>{`$ \\dfrac{${num1}}{${num3} + ${num2}} \\cdot ${num4} $`}</span>
      });

    generatedProblem.push({
      key: "13-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result13,
      format: <span>{`$ \\dfrac{${num1}}{${num3} + ${num2} \\cdot ${num4}} $`}</span>
    });

    generatedProblem.push({
      key: "14-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result14,
      format: <span>{`$ \\dfrac{${num1}}{${num3}} - \\dfrac{${num2}}{${num4}} $`}</span>
    });

    generatedProblem.push({
      key: "15-an",
      num1: num1,
      num2: num2,
      num3: num3,
      num4: num4,
      result: result15,
      format: <span>{`$ \\dfrac{${num1} - ${num2}}{${num3} + ${num4}} $`}</span>
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `
    $$ \\left( \\dfrac{4 + 7}{6 - 1} \\right)^{2} $$        
    \    
    $$ = \\left( \\dfrac{4 + 7}{5} \\right)^{2} $$
    \
    $$ = \\left( \\dfrac{11}{5} \\right)^{2} $$
    \
    $$ = \\dfrac{11^{2}}{5^{2}} $$
    \
    $$ = \\dfrac{121}{25} $$
    `
  }

  return (
    <div>
     
      <EquationLayout
        title={"Arithmetic Order Of Operations B"}
        instructions={"Simplify following the Order of Operations."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={3}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Order Of Operations B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}