import React, { useState } from 'react';

import CalculusLimits from './CalculusLimits';
import CalculusAverageRateofChange from './CalculusAverageRateofChange';
import CalculusPowerRule from './CalculusPowerRule';
import CalculusProductRule from './CalculusProductRule';
import CalculusQuotientRule from './CalculusQuotientRule';
import CalculusChainRule from './CalculusChainRule';
import CalculusRelatedRates from './CalculusRelatedRates';
import CalculusExtrema from './CalculusExtrema';
import CalculusIndefiniteIntegral from './CalculusIndefiniteIntegral';
import CalculusDefiniteIntegral from './CalculusDefiniteIntegral';
import CalculusUSubstitution from './CalculusUSubstitution';
import CalculusSeries from './CalculusSeries';
import CalculusRiemannSums from './CalculusRiemannSums';
import CalculusSeparableODE from './CalculusSeparableODE';
import CalculusSecondOrderODE from './CalculusSecondOrderODE';
import Button from 'react-bootstrap/Button';

const LIMITS = "limits"
const AVERAGE_RATE_OF_CHANGE = "average_rate_of_change"
const POWER_RULE = "power_rule"
const PRODUCT_RULE = "product_rule"
const QUOTIENT_RULE = "quotient_rule"
const CHAIN_RULE = "chain_rule"
const RELATED_RATES = "related_rates"
const EXTREMA = "extrema"
const INDEFINITE_INTEGRAL = "indefinite_integral"
const DEFINITE_INTEGRAL = "definite_integral"
const U_SUBSTITUTION = "u_substitution"
const SERIES = "series"
const RIEMANN_SUMS = "riemann_sums"
const SEPARABLE_ODE = "separable_ode"
const SECOND_ORDER_ODE = "second_order_ode"
const FUNCTION_TYPES = [
  LIMITS, AVERAGE_RATE_OF_CHANGE, POWER_RULE, PRODUCT_RULE, QUOTIENT_RULE, CHAIN_RULE, 
  RELATED_RATES, EXTREMA, INDEFINITE_INTEGRAL, DEFINITE_INTEGRAL, U_SUBSTITUTION, SERIES, RIEMANN_SUMS, SEPARABLE_ODE, 
  SECOND_ORDER_ODE
];

const functionTypeDisplayNames = {
  "limits" : "Limits",
  "average_rate_of_change" : "Average Rate of Change",
  "power_rule": "Power Rule",
  "product_rule": "Product Rule",
  "quotient_rule": "Quotient Rule",
  "chain_rule": "Chain Rule",
  "related_rates": "Related Rates",
  "extrema": "Extrema",
  "indefinite_integral": "Indefinite Integral",
  "definite_integral": "Definite Integral",
  "u_substitution": "U-Substitution",
  "series": "Series",
  "riemann_sums": "Riemann Sums",
  "separable_ode": "Separable ODE",
  "second_order_ode": "Second Order ODE"
}

export default function Calculus() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(LIMITS);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case LIMITS: {
        return <CalculusLimits />;
      }
      case AVERAGE_RATE_OF_CHANGE: {
        return <CalculusAverageRateofChange />;
      }
      case POWER_RULE: {
        return <CalculusPowerRule />;
      }
      case PRODUCT_RULE: {
        return <CalculusProductRule />;
      }
      case QUOTIENT_RULE: {
        return <CalculusQuotientRule />;
      }
      case CHAIN_RULE: {
        return <CalculusChainRule />;
      }
      case RELATED_RATES: {
        return <CalculusRelatedRates />;
      }
      case EXTREMA: {
        return <CalculusExtrema />;
      }
      case INDEFINITE_INTEGRAL: {
        return <CalculusIndefiniteIntegral />;
      }
      case DEFINITE_INTEGRAL: {
        return <CalculusDefiniteIntegral />;
      }
      case U_SUBSTITUTION: {
        return <CalculusUSubstitution />;
      }
      case SERIES: {
        return <CalculusSeries />;
      }
      case RIEMANN_SUMS: {
        return <CalculusRiemannSums />;
      }
      case SEPARABLE_ODE: {
        return <CalculusSeparableODE />;
      }
      case SECOND_ORDER_ODE: {
        return <CalculusSecondOrderODE />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}