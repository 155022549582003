import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { randomIntFromInterval, randomUniqueIntFromInterval } from '../../../../utils/mathHelpers';

export default function AdvancedPlay() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);
  const [numTable, setNumTable] = useState([[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]]);

  useEffect(() => {
    equationMaker();
  }, []);


  function equationMaker() {
    const num1 = randomIntFromInterval(-9,9);
    const num2 = randomIntFromInterval(-9,9);
    const num3 = randomIntFromInterval(-9,9);
    const num4 = randomIntFromInterval(-9,9);
    const num5 = randomIntFromInterval(-9,9);
    const num6 = randomIntFromInterval(-9,9);
    const num7 = randomIntFromInterval(-9,9);
    const num8 = randomIntFromInterval(-9,9);
    const num9 = randomIntFromInterval(-9,9);
    const num10 = randomIntFromInterval(-9,9);
    const num11 = randomIntFromInterval(-9,9);
    const num12 = randomIntFromInterval(-9,9);
    const xTotal = num1 + num2 + num3 + num4 + num5 + num6;
    const yTotal = num7 + num8 + num9 + num10 + num11 + num12;
    const xMean = Math.round(100*xTotal/6) / 100;
    const yMean = Math.round(100*yTotal/6) / 100;
    const diff = (num1-num7)+(num2-num8)+(num3-num9)+(num4-num10)+(num5-num11)+(num6-num12);
    const diff2 = ((num1-num7)**2)+((num2-num8)**2)+((num3-num9)**2)+((num4-num10)**2)+((num5-num11)**2)+((num6-num12)**2);
    const xDist = Math.round(100*((num1-xMean)+(num2-xMean)+(num3-xMean)+(num4-xMean)+(num5-xMean)+(num6-xMean)))/100;
    const yDist = Math.round(100*((num7-yMean)+(num8-yMean)+(num9-yMean)+(num10-yMean)+(num11-yMean)+(num12-yMean)))/100;
    const xSS = Math.round(100*(((num1-xMean)**2)+((num2-xMean)**2)+((num3-xMean)**2)+((num4-xMean)**2)+((num5-xMean)**2)+((num6-xMean)**2)))/100;
    const ySS = Math.round(100*(((num7-yMean)**2)+((num8-yMean)**2)+((num9-yMean)**2)+((num10-yMean)**2)+((num11-yMean)**2)+((num12-yMean)**2)))/100;
    const xVar = Math.round(100*xSS/5)/100;
    const yVar = Math.round(100*ySS/5)/100;
    const xSD = Math.round(100*Math.pow(xVar,0.5))/100;
    const ySD = Math.round(100*Math.pow(yVar,0.5))/100;
    setNumTable([[num1, num7], [num2, num8], [num3, num9], [num4, num10], [num5, num11], [num6, num12]])

    const generatedProblem = [];

    generatedProblem.push({
      key: "1",
      result1: xTotal,
      format: <span>{`$ \\text{Sum of X} = \\sum X $`}</span>
    });

    generatedProblem.push({
      key: "2",
      result1: yTotal,
      format: <span>{`$ \\text{Sum of Y} = \\sum Y $`}</span>
    });

    generatedProblem.push({
      key: "3",
      result1: xMean,
      format: <span>{`$ \\text{Mean of X} = \\bar{X} = \\dfrac{\\sum X}{n} $`}</span>
    });

    generatedProblem.push({
      key: "4",
      result1: yMean,
      format: <span>{`$ \\text{Mean of Y} = \\bar{Y} = \\dfrac{\\sum Y}{n} $`}</span>
    });

    generatedProblem.push({
      key: "5",
      result1: diff,
      format: <span>{`$ \\text{Sum of Differences} = \\sum (X-Y) $`}</span>
    });

    generatedProblem.push({
      key: "6",
      result1: diff2,
      format: <span>{`$ \\text{Sum of Differences Squared} = \\sum (X-Y)^{2} $`}</span>
    });

    generatedProblem.push({
      key: "7",
      result1: xDist,
      format: <span>{`$ \\text{Sum of Distances to Center for X} = \\sum (X - \\bar{X}) $`}</span>
    });

    generatedProblem.push({
      key: "8",
      result1: yDist,
      format: <span>{`$ \\text{Sum of Distances to Center for Y} = \\sum (Y - \\bar{Y}) $`}</span>
    });

    generatedProblem.push({
      key: "9",
      result1: xSS,
      format: <span>{`$ \\text{Sum of Squares for X} = \\sum (X - \\bar{X})^{2} $`}</span>
    });

    generatedProblem.push({
      key: "10",
      result1: ySS,
      format: <span>{`$ \\text{Sum of Squares for Y} = \\sum (Y - \\bar{Y})^{2} $`}</span>
    });

    generatedProblem.push({
      key: "11",
      result1: xVar,
      format: <span>{`$ \\text{Variance of X} = \\dfrac{\\sum (X - \\bar{X})^{2}}{n - 1} $`}</span>
    });

    generatedProblem.push({
      key: "12",
      result1: yVar,
      format: <span>{`$ \\text{Variance of Y} = \\dfrac{\\sum (Y - \\bar{Y})^{2}}{n - 1} $`}</span>
    });

    generatedProblem.push({
      key: "13",
      result1: xSD,
      format: <span>{`$ \\text{Standard Deviation of X} = \\sqrt{\\dfrac{\\sum (X - \\bar{X})^{2}}{n - 1}} $`}</span>
    });

    generatedProblem.push({
      key: "14",
      result1: ySD,
      format: <span>{`$ \\text{Standard Deviation of Y} = \\sqrt{\\dfrac{\\sum (Y - \\bar{Y})^{2}}{n - 1}} $`}</span>
    });



    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: `$ ${equation.result1} $`
    }
  }

  return (
    <div>
      <h1>Statistics Probability Table</h1>
      <span className="solve-for-text">Compute the probabilites.</span>

      <Row md={2}>
        <Col md={3} key={`prob-table`}>
          <Table responsive>
            <thead>
              <tr>
                <th>X</th>
                <th>Y</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numTable[0][0]}</td>
                <td>{numTable[0][1]}</td>
              </tr>
              <tr>
                <td>{numTable[1][0]}</td>
                <td>{numTable[1][1]}</td>
              </tr>
              <tr>
                <td>{numTable[2][0]}</td>
                <td>{numTable[2][1]}</td>
              </tr>
              <tr>  
                <td>{numTable[3][0]}</td>
                <td>{numTable[3][1]}</td>
              </tr>
              <tr>
                <td>{numTable[4][0]}</td>
                <td>{numTable[4][1]}</td>
              </tr>
              <tr>
                <td>{numTable[5][0]}</td>
                <td>{numTable[5][1]}</td>
              </tr>
              
            </tbody>
          </Table>
        </Col>
      </Row>

      <br/>

      <EquationLayout
      title={"Statistics Summation"}
      instructions={"Compute the Sums."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}
