import React, { useState } from 'react';

import StatisticsSummation from './StatisticsSummation';
import StatisticsCenter from './StatisticsCenter';
import StatisticsSpread from './StatisticsSpread';
import StatisticsProbabilityTable from './StatisticsProbabilityTable';
import StatisticsCombinatorics from './StatisticsCombinatorics';
import Button from 'react-bootstrap/Button';

const SUMMATION = "summation"
const CENTER = "center"
const SPREAD = "spread"
const PROBABILITY_TABLE = "probability_table"
const COMBINATORICS = "combinatorics"
const FUNCTION_TYPES = [SUMMATION, CENTER, SPREAD, PROBABILITY_TABLE, COMBINATORICS];

const functionTypeDisplayNames = {
  "summation": "Summation",
  "center": "Center",
  "spread": "Spread",
  "probability_table": "Probability Table",
  "combinatorics": "Combinatorics"
}

export default function Statistics() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(CENTER);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case SUMMATION: {
        return <StatisticsSummation />;
      }
      case CENTER: {
        return <StatisticsCenter />;
      }
      case SPREAD: {
        return <StatisticsSpread />;
      }
      case PROBABILITY_TABLE: {
        return <StatisticsProbabilityTable />;
      }
      case COMBINATORICS: {
        return <StatisticsCombinatorics />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}