import React from 'react';
import Container from 'react-bootstrap/Container';

export default function About() {
  return (
    <Container>
      <h1 style={{ color: "#0d6efd" }}>About</h1>
      <p>Unlock endless math practice with answers and step-by-step guidance across algebra, calculus, arithmetic, and trigonometry, empowering educators, students, and homeschoolers to master fundamental mathematical skills effortlessly.</p>

      <p>Mathematics is learned in 3 stages: Instruction, Practice, Feedback. There is an abundance of instruction on the internet. Now it’s time to practice. Repetition produces muscle memory. Muscle memory frees your mind to be creative. Algebra Maker allows you to sharpen your craft as a mathematician, so that the fundamentals turn into reflexes. Then you will have the freedom and skills to solve bigger problems.</p>
      
      <p>Algebra Maker offers a Help button for instruction, and a Show Answers button for feedback, but its essence is practice. So remember to show all your work, write neatly, and circle your answers.</p>
    </Container>
  )
}
