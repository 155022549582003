import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Outlet, NavLink } from "react-router-dom";
import "./styles.css"
import {Adsense} from '@ctrl/react-adsense';

import logo from '../MainContainer/images/algebramaker_logo.png';

export default function ContainerLayout() {
  return (
    <Container>
      <div>
        {/* Top Navigation */}
        <Row md={2}>
          <Col md={{ span: 4, offset: 9 }}>
            <NavLink className={({ isActive }) => isActive ? "active-link" : ""}to="/exercises">Exercises</NavLink> |{" "}
            <NavLink className={({ isActive }) => isActive ? "active-link" : ""} to="/about">About</NavLink> |{" "}
            <NavLink className={({ isActive }) => isActive ? "active-link" : ""} to="/contact">Contact Us</NavLink> |{" "}
            <NavLink className={({ isActive }) => isActive ? "active-link" : ""} to="/privacy">Privacy Policy</NavLink>
          </Col>
        </Row>

        {/* Logo */}
        <div style={{textAlign: 'center', marginTop: '5px'}}>
          <img src={logo} alt="Algebra Maker Logo" height={100} width={350}/>
        </div>

        <Adsense
          client="ca-pub-3202199629281193"
          slot="4266137006"
        />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

        {/* Content */}
        <div>
          <Outlet />
        </div>
      </div>
    </Container>
  )
}
