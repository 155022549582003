import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { funcMappings } from '../../../../utils/calculusHelper';
import { randomNumFromSet } from '../../../../utils/mathHelpers';

export default function CalculusProductRule() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [3, 4, 5, 6, 7, 8 ,9];
    const funcMappingsHash = funcMappings()
    const funMappingKeys = Object.keys(funcMappingsHash)
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let randomFuncName = randomNumFromSet(funMappingKeys);
      let secondRandomFuncName = randomNumFromSet(funMappingKeys);
      while (randomFuncName == secondRandomFuncName) {
        secondRandomFuncName = randomNumFromSet(funMappingKeys);
      }

      let randomFuncObj = funcMappingsHash[randomFuncName];
      let secondRandomFuncObj = funcMappingsHash[secondRandomFuncName];
      const { normalFunc, primeFunc, params } = randomFuncObj;
      const { normalFunc: secondNormalFunc, primeFunc: secondPrimeFunc, params: secondParams } = secondRandomFuncObj;
      let paramsForFunc = [];
      let paramsForSecondFunc = [];

      let x = 0
      while (x < params) {
        x = x + 1;
        paramsForFunc.push(randomNumFromSet(nums))
      }

      let y = 0
      while (y < secondParams) {
        y = y + 1;
        paramsForSecondFunc.push(randomNumFromSet(nums))
      }

      generatedProblem.push({
        key: `${step}-cpr`,
        firstNormalExpression: normalFunc(paramsForFunc),
        secondNormalExpression: secondNormalFunc(paramsForSecondFunc),
        firstPrimeExpression: primeFunc(paramsForFunc),
        secondPrimeExpression: secondPrimeFunc(paramsForSecondFunc),
        name: randomFuncName
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{d}{dx} \\Bigl( ${equation.firstNormalExpression} \\cdot ${equation.secondNormalExpression} \\Bigr)$`,
      answer: `$${equation.firstPrimeExpression} \\cdot ${equation.secondNormalExpression} + ${equation.firstNormalExpression} \\cdot ${equation.secondPrimeExpression}$`
    }
  }

  function helpText() {
    return `
        $$ \\frac{d}{dx} \\Bigl( \\text{Left}(x) \\cdot \\text{Right}(x) \\Bigr) = \\frac{d}{dx} \\Bigl( L(x) \\cdot R(x) \\Bigr) = \\frac{d}{dx} \\Bigl( L \\cdot R \\Bigr) $$
        \
        $$ = \\frac{d}{dx} \\Bigl( L \\Bigr) \\cdot R + L \\cdot \\frac{d}{dx} \\Bigl( R \\Bigr) $$
        \
        $$ = L' R + L R' $$
        \
        $$ \\frac{d}{dx} \\Bigl( 4 x^{3} \\cdot sin(x) \\Bigr) $$
        \
        $$ = \\frac{d}{dx} \\Bigl( 4 x^{3} \\Bigr) \\cdot sin(x) + 4 x^{3} \\cdot \\frac{d}{dx} \\Bigl( sin(x) \\Bigr)$$
        \
        $$ = (12 x^{2}) \\cdot sin(x) + 4 x^{3} \\cdot (cos(x)) $$
        \
        $$ = 12 x^{2} \\cdot sin(x) + 4 x^{3} \\cdot cos(x) $$
      `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Calculus Product Rule"}
        instructions={"Compute the derivative."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Product Rule Help", helpTextFunc: helpText}}
      />
    </div>
  );
}