import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';
import { trigFuncMappings, unitCircleMappings, determineAngle } from '../../../../utils/trigonometryHelper';

export default function TrigonometryUnitCircleB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let trigFunc = randomNumFromSet(trigFuncMappings())
      let inputs = randomNumFromSet(unitCircleMappings())
      let answer = trigFunc.answer(inputs)

      generatedProblem.push({
        key: `${step}-tucb`,
        trigFuncDisplay: trigFunc.funcDisplay,
        anglesToDisplay: determineAngle(inputs,trigFunc.funcName,(answer.sign === "-")),
        answer: `${answer.sign}${answer.value}`
      })
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let angles = []
    equation.anglesToDisplay.forEach(x => {
      angles.push(`\\dfrac{${x}\\pi}{12}`)
    })
    
    return {
      key: equation.key,
      equation: `$${equation.trigFuncDisplay} = ${equation.answer}$`,
      answer: `$x=${angles.join(",")}$`,
    }
  }

  function helpText() {
    return `
    $$ sec \\left( \\dfrac{15 \\pi}{12} \\right) $$
    \
    $$ = sec \\left( \\dfrac{3 \\cdot 5 \\pi}{3 \\cdot 4} \\right) $$
    \
    $$ = sec \\left( \\dfrac{5 \\pi}{4} \\right) $$
    \
    $$ = \\dfrac{1}{cos \\left( \\frac{5 \\pi}{4} \\right)} $$
    \
    $$ = \\dfrac{1}{\\left( \\dfrac{- \\sqrt{2}}{2} \\right)} $$
    \
    $$ = 1 \\div \\dfrac{- \\sqrt{2}}{2} $$
    \
    $$ = \\dfrac{1}{1} \\div \\dfrac{- \\sqrt{2}}{2} $$
    \
    $$ = \\dfrac{1}{1} \\cdot \\dfrac{2}{- \\sqrt{2}} $$
    \
    $$ = - \\frac{2}{\\sqrt{2}} $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Trigonometry Unit Circle B"}
        instructions={"Solve for x. May have multipule answers."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Unit Circle B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
