import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { randomIntFromInterval, randomUniqueIntFromInterval } from '../../../../utils/mathHelpers';

export default function StatisticsProbabilityTable() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);
  const [numTable, setNumTable] = useState([[0,0],[0,0]]);

  useEffect(() => {
    equationMaker();
  }, []);


  function equationMaker() {
    const num1 = randomIntFromInterval(1,99);
    const num2 = randomUniqueIntFromInterval(1,99,[num1])
    const num3 = randomUniqueIntFromInterval(1,99,[num1, num2])
    const num4 = randomUniqueIntFromInterval(1,99,[num1, num2, num3])
    const numTotal = num1 + num2 + num3 + num4
    setNumTable([[num1, num2], [num3, num4]])

    const generatedProblem = [];

    generatedProblem.push({
      key: "1",
      result1: num1 + num3,
      result2: numTotal,
      format: <span>{`$P(A)$`}</span>
    });

    generatedProblem.push({
      key: "2",
      result1: numTotal - (num1 + num3),
      result2: numTotal,
      format: <span>{`$P(not\\:A)$`}</span>
    });

    generatedProblem.push({
      key: "11",
      result1: num3,
      result2: num1 + num3,
      format: <span>{`$P(Y\\:if\\:A)$`}</span>
    });

    generatedProblem.push({
      key: "13",
      result1: num1,
      result2: numTotal,
      format: <span>{`$P(A\\:and\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "17",
      result1: numTotal - num4,
      result2: numTotal,
      format: <span>{`$P(A\\:or\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "3",
      result1: num2 + num4,
      result2: numTotal,
      format: <span>{`$P(B)$`}</span>
    });

    generatedProblem.push({
      key: "4",
      result1: numTotal - (num2 + num4),
      result2: numTotal,
      format: <span>{`$P(not\\:B)$`}</span>
    });

    generatedProblem.push({
      key: "10",
      result1: num2,
      result2: num1 + num2,
      format: <span>{`$P(B\\:if\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "16",
      result1: num4,
      result2: numTotal,
      format: <span>{`$P(B\\:and\\:Y)$`}</span>
    });

    generatedProblem.push({
      key: "20",
      result1: numTotal - num1,
      result2: numTotal,
      format: <span>{`$P(B\\:or\\:Y)$`}</span>
    });

    generatedProblem.push({
      key: "5",
      result1: num1 + num2,
      result2: numTotal,
      format: <span>{`$P(X)$`}</span>
    });

    generatedProblem.push({
      key: "6",
      result1: numTotal - (num1 + num2),
      result2: numTotal,
      format: <span>{`$P(not\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "9",
      result1: num1,
      result2: num1 + num2,
      format: <span>{`$P(A\\:if\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "14",
      result1: num2,
      result2: numTotal,
      format: <span>{`$P(B\\:and\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "18",
      result1: numTotal - num3,
      result2: numTotal,
      format: <span>{`$P(B\\:or\\:X)$`}</span>
    });

    generatedProblem.push({
      key: "7",
      result1: num3 + num4,
      result2: numTotal,
      format: <span>{`$P(Y)$`}</span>
    });

    generatedProblem.push({
      key: "8",
      result1: numTotal - (num3 + num4),
      result2: numTotal,
      format: <span>{`$P(not\\:Y)$`}</span>
    });

    generatedProblem.push({
      key: "12",
      result1: num4,
      result2: num2 + num4,
      format: <span>{`$P(Y\\:if\\:B)$`}</span>
    });

    generatedProblem.push({
      key: "15",
      result1: num3,
      result2: numTotal,
      format: <span>{`$P(A\\:and\\:Y)$`}</span>
    });

    generatedProblem.push({
      key: "19",
      result1: numTotal - num2,
      result2: numTotal,
      format: <span>{`$P(A\\:or\\:Y)$`}</span>
    });

    

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: `$\\dfrac{${equation.result1}}{${equation.result2}}$`
    }
  }

  return (
    <div>
      <h1>Statistics Probability Table</h1>
      <span className="solve-for-text">Compute the probabilites.</span>

      <Row md={2}>
        <Col md={3} key={`prob-table`}>
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>A</th>
                <th>B</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>X</td>
                <td>{numTable[0][0]}</td>
                <td>{numTable[0][1]}</td>
              </tr>
              <tr>
                <td>Y</td>
                <td>{numTable[1][0]}</td>
                <td>{numTable[1][1]}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <br/>

      <EquationLayout
      title={""}
      instructions={""}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={5}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}
