import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function CalculusUSubstitution() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [3, 4, 5, 6, 7];
    const nums2 = [2, 3, 4];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];
    let thirdNumber = nums2[Math.floor(Math.random()*nums2.length)];

    // Make sure numbers are not the same
    while(secondNumber === firstNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    let result1 = firstNumber - 1;
    let result2 = secondNumber - 1;
    let result3 = firstNumber*secondNumber*thirdNumber;
    let result4 = firstNumber*thirdNumber;
    let result5 = firstNumber*secondNumber;

    generatedProblem.push({
      key: "1-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int ${result3} x^{${result2}} \\cdot cos(${firstNumber}x^{${secondNumber}}) \\; dx $`,
      result: <span>{`$ ${thirdNumber} sin(${firstNumber}x^{${secondNumber}}) + C $`}</span>
    });

    generatedProblem.push({
      key: "2-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int - ${result3} x^{${result1}} \\cdot sin(${secondNumber}x^{${firstNumber}}) \\; dx $`,
      result: <span>{`$ ${thirdNumber} cos(${secondNumber}x^{${firstNumber}}) + C $`}</span>
    });


    generatedProblem.push({
      key: "3-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int \\frac{cos(Ln( ${thirdNumber} x))}{x} \\; dx $`,
      result: <span>{`$ sin(Ln( ${thirdNumber} x)) + C $`}</span>
    });

    generatedProblem.push({
      key: "4-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int - tan( ${thirdNumber} x) \\; dx $`,
      result: <span>{`$ \\dfrac{Ln(cos( ${thirdNumber} x))}{${thirdNumber}} + C $`}</span>
    });

    generatedProblem.push({
      key: "5-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int ${result5} sin^{${result1}}(x) \\cdot cos(x) \\; dx $`,
      result: <span>{`$ ${secondNumber} sin^{${firstNumber}}(x) + C $`}</span>
    });

    generatedProblem.push({
      key: "6-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int - ${result5} cos^{${result2}}(x) \\cdot sin(x) \\; dx $`,
      result: <span>{`$ ${firstNumber} cos^{${secondNumber}}(x) + C $`}</span>
    });

    generatedProblem.push({
      key: "7-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int \\frac{${result5} (Ln( ${thirdNumber} x))^{${result1}}}{x} \\; dx $`,
      result: <span>{`$ ${secondNumber} (Ln( ${thirdNumber} x))^{${firstNumber}} + C $`}</span>
    });

    generatedProblem.push({
      key: "8-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      format: `$ \\int ${result3} x^{${result1}} \\cdot e^{${secondNumber}x^{${firstNumber}}} \\; dx $`,
      result: <span>{`$ ${thirdNumber} e^{${secondNumber}x^{${firstNumber}}} + C $`}</span>
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ \\int 72x^{8} cos(4x^{9}) \\; dx $$
        \
        $$ = \\int cos(4x^{9}) \\cdot 72x^{8} \\; dx $$
        \
        $$ = \\int cos(4x^{9}) \\cdot 2 \\cdot \\underline{36x^{8} \\; dx} $$
        \
        $$ u = 4x^{9} $$
        \
        $$ \\dfrac{du}{dx} = \\dfrac{d}{dx} \\Bigl( u \\Bigr) = \\dfrac{d}{dx} \\Bigl( 4x^{9} \\Bigr) = u' = 9 \\cdot 4 \\cdot x^{9 - 1} = 36x^{8} $$
        \
        $$ dx \\cdot \\Bigl( \\dfrac{du}{dx} = 36x^{8} \\Bigr) $$
        \
        $$ \\dfrac{dx \\cdot du}{dx} = dx \\cdot 36x^{8} $$
        \
        $$ \\dfrac{du}{1} = 36x^{8} \\cdot dx $$
        \
        $$ du = \\underline{36x^{8} \\; dx} $$
        \
        $$ \\int cos(4x^{9}) \\cdot 2 \\cdot \\underline{36x^{8} \\; dx} $$
        \
        $$ = \\int cos(u) \\cdot 2 \\cdot \\underline{36x^{8} \\; dx} $$
        \
        $$ = \\int cos(u) \\cdot 2 \\cdot du $$
        \
        $$ = 2 \\cdot \\int cos(u) \\; du $$
        \
        $$ = 2 \\cdot (sin(u) + C) $$
        \
        $$ = 2 \\cdot (sin(4x^{9}) + C) $$
        \
        $$ = 2sin(4x^{9}) + 2C $$
        \
        $$ = 2sin(4x^{9}) + C $$
      `
  }

  return (
    <div>
      

      <EquationLayout
        title={"Calculus U-Substitution"}
        instructions={"Compute the integral by making a u-substitution."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "U-Substitution Help", helpTextFunc: helpText}}
      />
    </div>
  );
}