import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function Exp_LogChangeofBase() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.log(secondNumber)/Math.log(firstNumber);

      generatedProblem.push({
        key: `${step}-elcob`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1

      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ Log_{${equation.firstNumber}} (${equation.secondNumber}) $`,
      answer: `$ ${roundNumber(equation.result1)} $`
    }
  }

  function helpText() {
    return `
        $$ Log_{6} (15) $$
        \
        $$ = \\dfrac{Log_{e} (15)}{Log_{e} (6)} $$    
        \
        $$ = \\dfrac{Ln(15)}{Ln(6)} $$
        \
        $$ = \\dfrac{2.71}{1.79} $$
        \
        $$ = 1.51 $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Exp/Log Change of Base"}
        instructions={"Compute the Logarithm by changing the base to a more convenient one."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Change of Base", helpTextFunc: helpText}}
      />
    </div>
  );
}
