import React from 'react';
import Container from 'react-bootstrap/Container';

export default function PrivacyPolicy() {
  return (
    <Container>
      <h1 style={{ color: "#0d6efd" }}>Privacy Policy</h1>
      <p>Effective Date: June 11 2021</p>

      <p>Welcome to AlgebraMaker.com ("we," "our," or "us"). Your privacy is important to us, and we are committed to being transparent about how we handle information related to your visit.</p>

      <p>
        <div><b>1. Information We Collect</b></div>
        AlgebraMaker.com does not collect, store, or process any personal information from its users. We do not require account registration, store cookies, or track identifiable user data.
        However, we use Google Analytics to gather general, anonymous data about website traffic, such as:
        <ul>
          <li>The number of visitors</li>
          <li>Pages visited and time spent on the site</li>
          <li>Browser type and device information</li>
          <li>Geographic region (non-identifiable)</li>
        </ul>
        This data helps us improve our website and provide a better user experience.
      </p>

      <p>
        <div><b>2. Use of Google Analytics</b></div>
        Google Analytics collects anonymized data through cookies and similar tracking technologies. This data is used solely for website analytics purposes and is not shared or sold to third parties.
        If you wish to opt out of Google Analytics tracking, you can:
        <ul>
          <li>Disable cookies in your browser settings</li>
          <li>Use Google's <a href="https://tools.google.com/dlpage/gaoptout/">Opt-Out Browser Add-On</a></li>
        </ul>

        For more details on how Google Analytics handles data, please refer to <a href="https://policies.google.com/privacy">Google's Privacy Policy</a>.
      </p>

      <p>
        <div><b>3. Data Security</b></div>
        Since AlgebraMaker.com does not collect or store user data, there is no risk of personal data being exposed or compromised through our website.
      </p>

      <p>
        <div><b>4. Changes to This Privacy Policy</b></div>
        We may update this Privacy Policy from time to time. Any changes will be reflected on this page with an updated effective date.
      </p>

      <p>
        <div><b>5. Contact Us</b></div>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:camilod111@gmail.com" target="_blank" rel="noopener noreferrer">camilod111@gmail.com</a>
      </p>
    </Container>
  )
}
